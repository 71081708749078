var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"first-run"},[_c('div',{staticClass:"modal-shade"}),_c('div',{staticClass:"modal"},[_c('div',{directives:[{name:"anime",rawName:"v-anime",value:({
                translateY: ['-100px', 0],
                opacity: {
                    value: ['0.0', '1.0'],
                    duration: 5000,
                },
                delay: 500,
                duration: 750,
                elasticity: 100,
            }),expression:"{\r\n                translateY: ['-100px', 0],\r\n                opacity: {\r\n                    value: ['0.0', '1.0'],\r\n                    duration: 5000,\r\n                },\r\n                delay: 500,\r\n                duration: 750,\r\n                elasticity: 100,\r\n            }"}],staticClass:"modal-inner"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"page",attrs:{"active":_vm.page == 0,"id":0}},[_c('div',{staticClass:"content content-in",attrs:{"active":_vm.page == 0}},[_c('h1',[_vm._v("Welcome to Divinitor Minerva")]),_c('p',[_vm._v(" Minerva is a reference website for the MMORPG Dragon Nest. ")]),_vm._m(0),_c('p',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.changePage(1)}}},[_vm._v("Legal/Regulatory Information is provided here")]),_vm._v(". By continuing, you accept these terms. ")]),_c('div',{staticClass:"got-it"},[_c('button',{directives:[{name:"anime",rawName:"v-anime",value:({
                                    borderColor: ['#98BCD4', '#dcf1ff', '#98BCD4', '#98BCD4'],
                                    loop: true,
                                    duration: 3000,
                                    delay: 750,
                                    easing: 'easeInOutExpo'
                                }),expression:"{\r\n                                    borderColor: ['#98BCD4', '#dcf1ff', '#98BCD4', '#98BCD4'],\r\n                                    loop: true,\r\n                                    duration: 3000,\r\n                                    delay: 750,\r\n                                    easing: 'easeInOutExpo'\r\n                                }"}],staticClass:"dv-button",on:{"click":function($event){return _vm.changePage(2, 1)}}},[_vm._v(" Let's get started ")])])])]),_c('div',{staticClass:"page",attrs:{"active":_vm.page == 1,"id":1}},[_c('div',{staticClass:"content content-in"},[_c('h4',[_vm._v("Quasi-legal stuff")]),_vm._m(1),_c('div',{staticClass:"got-it"},[_c('button',{directives:[{name:"anime",rawName:"v-anime",value:({
                                    borderColor: ['#98BCD4', '#dcf1ff', '#98BCD4', '#98BCD4'],
                                    loop: true,
                                    duration: 3000,
                                    delay: 750,
                                    easing: 'easeInOutExpo'
                                }),expression:"{\r\n                                    borderColor: ['#98BCD4', '#dcf1ff', '#98BCD4', '#98BCD4'],\r\n                                    loop: true,\r\n                                    duration: 3000,\r\n                                    delay: 750,\r\n                                    easing: 'easeInOutExpo'\r\n                                }"}],on:{"click":function($event){return _vm.changePage(2, 1)}}},[_vm._v(" Sounds Good ")])])])]),_c('div',{staticClass:"page",attrs:{"active":_vm.page == 2,"id":2}},[_c('div',{staticClass:"content content-in"},[_c('h1',[_vm._v("Select your region")]),_c('p',[_vm._v(" Please select your Dragon Nest service region. You can change this later by clicking on the globe icon in the top right corner. ")]),(!_vm.regionsLoading)?_c('div',{staticClass:"region-selector"},[_c('region-select',{attrs:{"regions":_vm.regions,"selection":_vm.selectedRegionCode},on:{"select":_vm.selectRegion}})],1):_vm._e(),(_vm.regionsError)?_c('div',{staticClass:"region-error"},[_c('div',{staticClass:"warn toast"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"heading"},[_vm._v("Oops")]),_c('p',[_vm._v(" Sorry! There might be an issue with the service: "),_c('br'),_vm._v(" "+_vm._s(_vm.regionsError)+" ")])])]),_c('div',{staticClass:"got-it"},[_c('button',{directives:[{name:"anime",rawName:"v-anime",value:({
                                        borderColor: ['#98BCD4', '#dcf1ff', '#98BCD4', '#98BCD4'],
                                        loop: true,
                                        duration: 3000,
                                        delay: 750,
                                        easing: 'easeInOutExpo'
                                    }),expression:"{\r\n                                        borderColor: ['#98BCD4', '#dcf1ff', '#98BCD4', '#98BCD4'],\r\n                                        loop: true,\r\n                                        duration: 3000,\r\n                                        delay: 750,\r\n                                        easing: 'easeInOutExpo'\r\n                                    }"}],on:{"click":function($event){return _vm.changePage(3, 2)}}},[_vm._v(" Continue ")])])]):_vm._e()])]),_c('div',{staticClass:"page",attrs:{"active":_vm.page == 3,"id":3}},[_c('div',{staticClass:"content content-in"},[_c('h1',[_vm._v("Log in with Devlin (Optional)")]),_c('p',[_vm._v(" You can log in using Divinitor Devlin using the Log In button on the top right to gain access to certain limited features. ")]),_c('div',{staticClass:"got-it"},[_c('button',{directives:[{name:"anime",rawName:"v-anime",value:({
                                    borderColor: ['#98BCD4', '#dcf1ff', '#98BCD4', '#98BCD4'],
                                    loop: true,
                                    duration: 3000,
                                    delay: 750,
                                    easing: 'easeInOutExpo'
                                }),expression:"{\r\n                                    borderColor: ['#98BCD4', '#dcf1ff', '#98BCD4', '#98BCD4'],\r\n                                    loop: true,\r\n                                    duration: 3000,\r\n                                    delay: 750,\r\n                                    easing: 'easeInOutExpo'\r\n                                }"}],staticClass:"dv-button",on:{"click":_vm.finish}},[_vm._v(" Awesome, let's go ")])]),(false)?[(_vm.authenticated && _vm.loginStatus == 0)?_c('span',[_c('h1',[_vm._v("You're logged in")]),_c('p',[_vm._v(" Nice! You've logged in to Minerva via Devlin as ")]),_c('div',{staticClass:"user-info"},[(_vm.avatar)?_c('img',{staticClass:"left discord-avatar",attrs:{"src":_vm.avatar,"alt":"?"}}):_vm._e(),_c('div',{staticClass:"right"},[_c('strong',[_vm._v(_vm._s(_vm.authInfo.profile.username))]),_c('span',{staticClass:"discrim"},[_vm._v("#"+_vm._s(_vm.authInfo.profile.discriminator))]),_c('div',{staticClass:"id"},[_c('span',[_vm._v(_vm._s(_vm.authInfo.profile.id))])])])]),_c('div',{staticClass:"got-it"},[_c('button',{staticClass:"dv-button",on:{"click":_vm.logOut}},[_vm._v(" No stop wait log me out ")]),_c('button',{directives:[{name:"anime",rawName:"v-anime",value:({
                                        borderColor: ['#98BCD4', '#dcf1ff', '#98BCD4', '#98BCD4'],
                                        loop: true,
                                        duration: 3000,
                                        delay: 750,
                                        easing: 'easeInOutExpo'
                                    }),expression:"{\r\n                                        borderColor: ['#98BCD4', '#dcf1ff', '#98BCD4', '#98BCD4'],\r\n                                        loop: true,\r\n                                        duration: 3000,\r\n                                        delay: 750,\r\n                                        easing: 'easeInOutExpo'\r\n                                    }"}],staticClass:"dv-button",on:{"click":_vm.finish}},[_vm._v(" Awesome, let's go ")])])]):_c('span',[_c('h1',[_vm._v("Log in with Devlin (Optional)")]),_c('p',[_vm._v(" You can log in using Divinitor Devlin to gain access to members-only features. There are some features that are available to guests, however, so if you're just here for that then meander on down to that \"No thanks\" link below. ")]),(_vm.loginStatus == 3)?_c('div',{staticClass:"discord-success"},[_vm._v(" Success! You are now logged in. ")]):(_vm.loginStatus == 0)?_c('div',{staticClass:"discord-login"},[_c('discord-hero-button',{staticClass:"big-login-button",attrs:{"optional":false},on:{"done":_vm.nextPage}}),_c('br'),_c('div',{staticClass:"no-thanks"},[_c('a',{attrs:{"href":"#"},on:{"click":_vm.finish}},[_vm._v("No thanks, just let me in")])])],1):(_vm.loginStatus == 1)?_c('div',{directives:[{name:"anime",rawName:"v-anime",value:({
                                    translateX: [-50, 0],
                                    opacity: {
                                        value: ['0.0', '1.0'],
                                        duration: 5000,
                                    },
                                    delay: 500,
                                    duration: 750,
                                    elasticity: 100,
                                }),expression:"{\r\n                                    translateX: [-50, 0],\r\n                                    opacity: {\r\n                                        value: ['0.0', '1.0'],\r\n                                        duration: 5000,\r\n                                    },\r\n                                    delay: 500,\r\n                                    duration: 750,\r\n                                    elasticity: 100,\r\n                                }"}],staticClass:"discord-waiting"},[_c('div',{staticClass:"loading-blinker"}),_vm._v(" Waiting for you to log in on the Discord popup window... ")]):(_vm.loginStatus == 2)?_c('div',{staticClass:"discord-waiting"},[_c('div',{staticClass:"loading-blinker"}),_vm._v(" Verifying login information... ")]):(_vm.loginStatus == 4)?_c('div',{staticClass:"discord-error"},[_c('h4',{staticClass:"head"},[_vm._v(" Whoops, we couldn't log you in with Discord :( ")]),(_vm.discordLoginError)?_c('div',{staticClass:"detail"},[_vm._v(" "+_vm._s(_vm.discordLoginError.message)+" ")]):_vm._e(),_c('div',{staticClass:"button-row"},[_c('button',{staticClass:"ok",on:{"click":_vm.openDiscordAuth}},[_vm._v("Try again")]),_c('button',{on:{"click":_vm.finish}},[_vm._v("Skip for now")])])]):_vm._e(),(_vm.loginStatus == 3)?_c('div',{staticClass:"got-it"},[_c('button',{directives:[{name:"anime",rawName:"v-anime",value:({
                                            borderColor: ['#98BCD4', '#dcf1ff', '#98BCD4', '#98BCD4'],
                                            loop: true,
                                            duration: 3000,
                                            delay: 750,
                                            easing: 'easeInOutExpo'
                                        }),expression:"{\r\n                                            borderColor: ['#98BCD4', '#dcf1ff', '#98BCD4', '#98BCD4'],\r\n                                            loop: true,\r\n                                            duration: 3000,\r\n                                            delay: 750,\r\n                                            easing: 'easeInOutExpo'\r\n                                        }"}],on:{"click":_vm.finish}},[_vm._v(" Awesome, let's go ")])]):_vm._e()])]:_vm._e()],2)]),_c('div',{staticClass:"page-indicator"},[_c('div',{staticClass:"indicator",attrs:{"active":_vm.page == 0,"disabled":_vm.loginStatus == 1 || _vm.loginStatus == 2},on:{"click":function($event){return _vm.changePage(0)}}}),_c('div',{staticClass:"indicator",attrs:{"active":_vm.page == 2,"disabled":_vm.completedStep < 1 || _vm.loginStatus == 1 || _vm.loginStatus == 2},on:{"click":function($event){return _vm.changePage(2)}}}),_c('div',{staticClass:"indicator",attrs:{"active":_vm.page == 3,"disabled":_vm.completedStep < 2,"bad":_vm.loginStatus == 4},on:{"click":function($event){return _vm.changePage(3)}}})])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_vm._v(" Please note that this site is in limited beta. There will be bugs, incomplete features, and duct tape everywhere. If you encounter an issue, please let "),_c('strong',[_vm._v("Vahr")]),_vm._v(" know. ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"legal"},[_vm._v(" This site does not use cookies for any of its functionality. We do use "),_c('a',{attrs:{"href":"https://docs.microsoft.com/en-us/azure/application-insights/app-insights-overview","target":"_blank"}},[_vm._v("Azure Application Insights")]),_vm._v(" to gather basic usage and performance information to monitor site performance, issues, and usage. You can opt out of this data collection by blocking cookies or using a tracker blocker. If you log in to Minerva with Discord, your Discord information is only used to verify eligibility to access limited features and prevent fraudulent use. You may opt out of this by not signing in with Discord. A small amount of your browser's "),_c('a',{attrs:{"href":"https://developer.mozilla.org/en-US/docs/Web/API/Storage/LocalStorage","target":"_blank"}},[_vm._v("local storage")]),_vm._v(" is used to store your login token, login name, preferences, and a few bits of information (like if you have seen this message you're reading right now or not) necessary to the proper operation of the site. You may clear this data at any time by using your browser's local storage options. Vahr does not guarantee or have any obligation to the availability of this site and its services. These terms may change at any time without prior notice, however if they do change you will be notified the next time you access this site. ")])}]

export { render, staticRenderFns }