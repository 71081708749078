







import Vue from 'vue'
export default Vue.extend({
    methods: {
        goBack() {
            this.$router.back();
        },
    },
})
