

































































import Vue from 'vue';

import { Actions } from '@/storemutations';
import DiscordLoginProvider from "@/api/DiscordLoginProvider";
import AuthProvder from '@/api/AuthProvider';
import IRedeemedAuthResult from '@/models/auth/IRedeemedAuthResult';
import AuthProvider from '@/api/AuthProvider';

enum LoginStatus {
    IDLE = 0,
    DISCORD_PROMPT = 1,
    VERIFICATION = 2,
    DONE = 3,
    ERROR = 4,
};

interface IData {
    loginStatus: LoginStatus;
    discordLoginError: Error|null;
}

export default Vue.extend({
    props: {
        "optional": {
            type: Boolean as () => boolean,
        }
    },
    data(): IData {
        return {
            loginStatus: LoginStatus.IDLE,
            discordLoginError: null,
        };
    },
    methods: {
        finish() {
            this.$emit("finish");
        },
        skip() {
            this.$emit("skip");
        },
        openDiscordAuth() {
            this.$anime({
                targets: ".page-indicator .indicator[bad]",
                translateY: [-3, 0, 0],
                rotate: ["+135deg"],
                duration: 2000
            });
            this.$anime({
                targets: ".modal-inner",
                rotate: ["0deg"]
            });
            this.loginStatus = LoginStatus.DISCORD_PROMPT;
            this.discordLoginError = null;
            setTimeout(async() => {
                try {
                    const token = await AuthProvider.performDevlinOAuth2Flow();
                    this.loginStatus = LoginStatus.DONE;
                    this.$store.dispatch(Actions.SetAuthToken, token);
                    this.$emit("done");
                } catch (error) {
                    console.error(error);
                    this.loginStatus = LoginStatus.ERROR;
                    this.discordLoginError = error;
                    Vue.nextTick().then(() => {
                        this.$anime({
                            targets: ".page-indicator .indicator[bad]",
                            translateX: [-5, 5, -2, 2, -3, 0],
                            translateY: [0, 3],
                            rotate: ["+240deg"],
                            elasticity: 500,
                            duration: 1000
                        });
                        this.$anime({
                            targets: ".modal-inner",
                            rotate: ["+2deg"],
                            delay: 200,
                            elasticity: 800,
                        });
                    });
                }
            }, 1000);

        }
    }
});
