











































import Vue from 'vue';

import StatCalcProvider,{ IStatCalcResult } from "@/api/StatCalcProvider";
import ITypedMap from '@/models/util/ITypedMap';
import { getFeatureState, netFeatures } from '@/api/FeaturesProvider';

interface ILimits {
    minPercent: number;
    maxPercent: number;
    minStat: number;
    maxStat: number;
}

interface IData {
    level: number;
    value: number;
    percent: number;
    limits: ILimits;
    inputType: string;
}

function getLevelCap(): number {
    if (getFeatureState(netFeatures.Use40Cap)) {
        return 40;
    }
    
    if (getFeatureState(netFeatures.Use70Cap)) {
        return 70;
    }
    
    return 95;
}

export default Vue.extend({
    props: {
        "stat": {
            type: String as () => string,
        }
    },
    data(): IData {
        return {
            level: getLevelCap(),
            value: 0,
            percent: 0,
            limits: {
                minPercent: 0,
                maxPercent: 100,
                minStat: 0,
                maxStat: 99999
            },
            inputType: "STAT",
        };
    },
    watch: {
        level() {
            this.loadStatCap().then(() => {
                this.calculate();
            });
        },
        percent() {
            if (this.inputType == "PERCENT") {
                this.calculate();
            }
        },
        value() {
            if (this.inputType == "STAT") {
                this.calculate();
            }
        },
    },
    computed: {
        isCritDmg(): boolean {
            return this.stat === "criticalDamage";
        },
    },
    created() {
        this.loadStatCap();
    },
    methods: {
        loadStatCap() {
            if (isNaN(this.level) || this.level <= 0 || this.level > 100) {
                this.limits.maxStat = 0;
                return Promise.resolve();
            }

            return StatCalcProvider.getStatCap(this.level, this.stat).then((caps) => {
                // Check again in case we beat the network
                if (isNaN(this.level) || this.level <= 0 || this.level > 100) {
                    this.limits.maxStat = 0;
                    return;
                }

                this.limits.maxPercent = caps.percent * 100;
                this.limits.maxStat = Math.round(caps.value);
                this.limits.minPercent = 0;

                if (this.isCritDmg) {
                    this.limits.maxPercent += 200;
                    this.limits.minPercent += 2;
                }

                this.percent = this.getTruncatedPercent() * 100;
            });
        },
        unfocus(evt: Event) {
            //@ts-ignore
            evt.target.blur();
        },
        select(evt: Event) {
            if (evt.target == null || !(evt.target instanceof Element) || evt.target.parentNode == null) {
                return;
            }

            this.inputType = (<ITypedMap<any>>(<HTMLElement>evt.target.parentNode).attributes)["value"].value;
            (this.$refs[this.inputType] as HTMLFormElement).focus();
        },
        capValue(evt: Event) {
            this.value = Number(this.value);
            if (this.value > 9999999) {
                this.value = 9999999;
                return false;
            } 
            if (this.value < this.limits.minStat) {
                this.value = this.limits.minStat;
                return false;
            }
        },
        capPercent(evt: Event) {
            this.percent = Number(this.percent);
            if (this.percent > this.limits.maxPercent) {
                this.percent = this.limits.maxPercent;
                return false;
            }
            if (this.percent < this.limits.minPercent) {
                this.percent = this.limits.minPercent;
                return false;
            }
        },
        getTruncatedValue() {
            return Math.min(this.limits.maxStat, Math.max(this.limits.minStat, this.value));
        },
        getTruncatedPercent() {
            return Math.min(this.limits.maxPercent, Math.max(this.limits.minPercent, this.percent / 100));
        },
        async calculate() {
            if (this.inputType == "STAT") {
                let percentVal = await StatCalcProvider.calculatePercent(this.level, this.stat, this.getTruncatedValue());
                let percent = Math.floor(percentVal.percent * 100);
                if (this.isCritDmg) {
                    percent += 200;
                }

                this.percent = percent;
            } else {
                let percent = this.getTruncatedPercent();
                if (this.isCritDmg) {
                    percent -= 2;
                }

                let statVal = await StatCalcProvider.calculateValue(this.level, this.stat, percent);
                this.value = Math.floor(statVal.value);
            }
        },
    },
});
