








































































































































































































































































import Vue from "vue";
import {mapGetters} from "vuex";
import store from "@/store";

import RegionSelect from "@/components/region/RegionSelect.vue";

import RegionProvider from "@/api/RegionProvider";
import IRegion from '@/models/region/IRegion';
import { Actions } from '@/storemutations';
import DiscordLoginProvider from "@/api/DiscordLoginProvider";
import IRedeemedAuthResult from '@/models/auth/IRedeemedAuthResult';
import IDevlinTokenResponse from '@/models/auth/IDevlinTokenResponse';
import DiscordHeroButton from '@/components/auth/DiscordHeroButton.vue';

enum LoginStatus {
    IDLE = 0,
    DISCORD_PROMPT = 1,
    VERIFICATION = 2,
    DONE = 3,
    ERROR = 4,
};

interface IData {
    page: number;
    completedStep: number;
    regionsLoading: boolean;
    regionsError: string;
    regions: IRegion[];
    loginStatus: LoginStatus;
    discordLoginError: Error|null;
}

export default Vue.extend({
    data(): IData {
        return {
            page: -1,
            completedStep: -1,
            regionsLoading: false,
            regionsError: "",
            regions: [],
            loginStatus: LoginStatus.IDLE,
            discordLoginError: null,
        };
    },
    components: {
        RegionSelect,
        DiscordHeroButton,
    },
    mounted() {
        this.loadRegions();
        setTimeout(() => this.changePage(0), 500);
    },
    computed: {
        selectedRegionCode(): string {
            return this.$store.state.regionCode;
        },
        ...mapGetters([
            "authenticated",
            "authInfo",
        ]),
        avatar(): string|null {
            const authInfo = (this as any).authInfo as IDevlinTokenResponse;
            if (authInfo && authInfo.profile) {
                let av = authInfo.profile.avatar;
                if (!av) {
                    // determine discord default avatar using discrim
                    const discrim = Number(authInfo.profile.discriminator) % 5;
                    return `https://cdn.discordapp.com/embed/avatars/${discrim}.png?size=128`;
                }

                let ext = "png";
                if (av.startsWith("a_")) {
                    ext = "gif";
                }
                return `https://cdn.discordapp.com/avatars/${authInfo.profile.id}/${av}.${ext}?size=128`;
            }

            return null;
        },
    },
    methods: {
        changePage(index: number, step?: number) {
            this.$anime({
                targets: ".modal-inner",
                rotate: ["0deg"]
            });
            if (step !== undefined) {
                this.completedStep = step;
            }

            const old = this.page;
            this.page = index;
            if (old !== index) {
                //  Reset discord if errored
                if (this.loginStatus == LoginStatus.ERROR) {
                    this.$anime({
                        targets: ".page-indicator .indicator[bad]",
                        translateY: [-3, 0, 0],
                        rotate: ["+135deg"],
                        duration: 2000
                    });
                    this.loginStatus = LoginStatus.IDLE;
                    this.discordLoginError = null;
                }

                let translationX = -30;
                let translationY = 0;
                if (old < index) {
                    translationX *= -1;
                }

                if (old === -1) {
                    translationX = 0;
                    translationY = -50;
                }

                Vue.nextTick().then(() => {
                    this.$anime({
                        targets: ".page[active] .content-in",
                        translateX: [translationX, 0],
                        translateY: [translationY, 0],
                        elasticity: 100,
                        opacity: {
                            value: ['0.0', '1.0'],
                            delay: 100,
                            duration: 2000,
                        },
                        delay: 100,
                    });
                    this.$anime({
                        targets: ".page-indicator .indicator[active]",
                        rotate: ["+135deg"],
                        duration: 1000,
                    });
                });
            }
        },
        nextPage() {
            this.changePage(this.page + 1);
        },
        nextStep() {
            this.changePage(this.page, this.completedStep + 1);
        },
        loadRegions() {
            this.regionsLoading = true;
            RegionProvider.listRegions().then((regions) => {
                this.regions = regions;
                this.regionsLoading = false;
            }, (error: Error) => {
                // Error loading regions, skip
                this.regionsError = "Failed to get regions";
            });
        },
        selectRegion(region: IRegion) {
            this.$store.dispatch(Actions.SetRegion, region.shortName);
            this.changePage(3, 2);
        },
        openDiscordAuth() {
            this.$anime({
                targets: ".page-indicator .indicator[bad]",
                translateY: [-3, 0, 0],
                rotate: ["+135deg"],
                duration: 2000
            });
            this.$anime({
                targets: ".modal-inner",
                rotate: ["0deg"]
            });
            this.loginStatus = LoginStatus.DISCORD_PROMPT;
            this.discordLoginError = null;
            setTimeout(() => {
                DiscordLoginProvider.performDiscordOAuth2Flow().then((code) => {
                    this.loginStatus = LoginStatus.VERIFICATION;
                    return DiscordLoginProvider.redeemCode(code);
                }).then((authResult: IRedeemedAuthResult) => {
                    this.loginStatus = LoginStatus.DONE;
                    this.$store.dispatch(Actions.SetAuthToken, authResult);
                }).catch((error: Error) => {
                    console.error(error);
                    this.loginStatus = LoginStatus.ERROR;
                    this.discordLoginError = error;
                    Vue.nextTick().then(() => {
                        this.$anime({
                            targets: ".page-indicator .indicator[bad]",
                            translateX: [-5, 5, -2, 2, -3, 0],
                            translateY: [0, 3],
                            rotate: ["+240deg"],
                            elasticity: 500,
                            duration: 1000
                        });
                        this.$anime({
                            targets: ".modal-inner",
                            rotate: ["+2deg"],
                            delay: 200,
                            elasticity: 800,
                        });
                    });
                });
            }, 1000);
        },
        logOut() {
            this.$store.dispatch(Actions.ClearAuthToken);
            this.loginStatus = LoginStatus.IDLE;
        },
        finish() {
            this.$anime({
                targets: ".first-run",
                opacity: [1.0, 0.0],
                duration: 1000,
                easing: "easeOutCubic",
            }).finished.then(() => {
                this.$store.dispatch(Actions.SetFirstRunCompleted);
            });
        }
    }
});
